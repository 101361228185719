import React from "react"

import SEO from "../../components/seo"
import styles from "./styles.module.scss"
import Header from "../../components/header"
import Confetti from "../../components/confetti"

import SignUpForm from "../../../static/sign-up-form.pdf"

export default function ContactPage() {
  const clearInputs = () => {
    document.getElementById("name").value = ""
    document.getElementById("birthday").value = ""
    document.getElementById("email").value = ""
    document.getElementById("message").value = ""
  }

  const sendEmail = () => {
    const sendButton = document.getElementById("send")

    const name = document.getElementById("name").value
    const birthday = document.getElementById("birthday").value
    const email = document.getElementById("email").value
    const topic = document.getElementById("topic").value
    const message = document.getElementById("message").value

    if (
      name === "" ||
      birthday === "" ||
      email === "" ||
      topic === "" ||
      message === ""
    ) {
      sendButton.innerText = "Fill in all fields"
      return
    }

    sendButton.disabled = true
    sendButton.innerText = "Sending.."

    var body = JSON.stringify({
      name: name,
      birthday: birthday,
      email: email,
      topic: topic,
      message: message,
    })

    fetch(process.env.MAIL_API_URL, {
      method: "POST",
      body: body,
    })
      .then(response => {
        console.log(response)
        clearInputs()
        sendButton.innerHTML = "🎉🎉"
        sendButton.disabled = false
      })
      .catch(error => {
        console.log(error)
        sendButton.innerHTML = "❌❌"
        sendButton.disabled = false
      })
  }

  return (
    <>
      <SEO title="Contact" />
      <Header />
      <Confetti />
      <section className={styles.contact}>
        <div className={styles.contact__container}>
          <div className={styles.contact__container__text}>
            <p>
              Would you like to have a philosophical conversation with
              Noelle.28.12.1993, the head of the insti- tute, about your
              birthday experiences, wishes, rumours, expectations or other? To
              unpack some of your own pressure points and/or to contribute to
              the collaborative Philosophy of Birthdays? Download{" "}
              <a href={SignUpForm} download>
                this form
              </a>{" "}
              to request a birthday session over video call.
            </p>
            <p>
              Please complete all fields and we will get back to you with an
              appointment within 1-3 working days.
            </p>
            <p>
              Completed forms can be sent to{" "}
              <span>info@happybirthdayto.name</span>.
            </p>
            <p>
              For other questions or inquiries use the contact form on the right
              or our contact info below.
            </p>
            <p>
              We are looking forward to discuss your birthday matter and
              eventually add your expertise to the collective birthday
              philosophy!
            </p>
            <div className={styles.contact__container__text__details}>
              <p>contact</p>
              <p>info@happybirthdayto.name</p>
              <p>28121993@happybirthdayto.name</p>
            </div>
          </div>
          <div className={styles.contact__container__form}>
            <label htmlFor="name">
              name
              <input id="name"></input>
            </label>
            <label htmlFor="birthday">
              date of birth
              <input type="date" id="birthday"></input>
            </label>
            <label htmlFor="email">
              email
              <input type="email" id="email"></input>
            </label>
            <label htmlFor="topic">
              topic
              <select name="topics" id="topic">
                <option value="ageing">ageing</option>
                <option value="attention">attention</option>
                <option value="anti-social distance birthday">
                  anti-social distance birthday
                </option>
                <option value="congratulations">congratulations</option>
                <option value="baking a cake">baking a cake</option>
                <option value="calendar">calendar</option>
                <option value="decoration">decoration</option>
                <option value="individualism">individualism</option>
                <option value="invitation">invitation</option>
                <option value="presents">presents</option>
                <option value="social currency">social currency</option>
                <option value="spotlight">spotlight</option>
                <option value="unique versus universal">
                  unique versus universal
                </option>
                <option value="other">other</option>
              </select>
            </label>
            <label htmlFor="message">
              message
              <textarea rows="8" id="message"></textarea>
            </label>
            <button onClick={sendEmail} id="send">
              send
            </button>
          </div>
        </div>
      </section>
    </>
  )
}
